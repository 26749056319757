import { useMemo } from 'react';

import useProfileSizes from '~/pdp/utils/useProfileSizes';
import useGender from '~/shared/utils/useGender';
import { useMembership, useLoyalty } from '~/techstyle-shared/react-accounts';
import { useIntl } from '~/techstyle-shared/react-intl';
import { useMarketingDisposition } from '~/techstyle-shared/react-marketing';
import { useSession } from '~/techstyle-shared/redux-core';

import { getBuilderMembershipStatus } from './getBuilderMembershipStatus';
import { getBuilderPageTargeting } from './getBuilderPageTargeting';

export default function useBuilderTargeting({ isPreview = false }) {
  // There may be some tradeoff efficiencies to preload the user attributes at the SSR level
  // to minimize re-renders on initial load. However, this should be done sparingly
  // as it can increase the time to first byte (TTFB) and slow down the initial page load.
  const { isLoggedIn } = useSession();
  const { locale } = useIntl();
  const { gender } = useGender();
  const membership = useMembership();
  const loyalty = useLoyalty();
  const membershipStatus = getBuilderMembershipStatus(membership, isLoggedIn);
  const vipPlusPerksAvailable = membership?.vipPlusPerksAvailable;
  const disposition = useMarketingDisposition();
  const experienceId = disposition?.experience;
  const { profileSizes } = useProfileSizes({ isPdp: false });

  // Because objects are not referentially equal between re-renders,
  // we need to destructure them into individual primitive values to efficiently
  // trigger the dependency array of the useMemo hook
  const {
    size_bottom: bottomSize,
    size_top: topSize,
    size_bra: braSize,
  } = profileSizes || {};

  const pageOptions = useMemo(
    () => ({
      experienceId,
      gender,
      locale,
      membershipStatus,
      isLoggedIn,
      isPreview,
      loyalty,
      vipPlusPerksAvailable,
      bottomSize,
      topSize,
      braSize,
    }),
    [
      experienceId,
      gender,
      locale,
      membershipStatus,
      isLoggedIn,
      isPreview,
      loyalty,
      vipPlusPerksAvailable,
      bottomSize,
      topSize,
      braSize,
    ]
  );

  // Because objects are not referentially equal between re-renders,
  // this will always return a new object, so we need to memoize the return value
  const builderPageTargetingOptions = useMemo(
    () => getBuilderPageTargeting(pageOptions),
    [pageOptions]
  );

  return builderPageTargetingOptions;
}
