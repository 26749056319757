export function getHomepageMemberStatus(membership) {
  const {
    membershipId,
    isVip,
    isLead,
    isDowngraded,
    getCurrentPostRegHours: getCurrentPostRegHoursFromMembership,
  } = membership;

  const getCurrentPostRegHours = () => {
    if (getCurrentPostRegHoursFromMembership) {
      return getCurrentPostRegHoursFromMembership();
    }
    return null;
  };

  const isPostReg = membershipId && !isVip && getCurrentPostRegHours() < 1;

  return {
    isVip,
    isLead,
    isDowngraded,
    isPostReg,
  };
}
