import config from 'config';

import { MembershipRewardTierIdMapping } from '../../shared/constants/loyaltyTiers';
import { isFringeSize } from '../../shared/utils/isFringeSize';
import { Gender } from '../../techstyle-shared/react-accounts';

export const getBuilderPageTargeting = (pageOptions = {}) => {
  const brand = config.get('public.brand.name');

  const {
    experienceId,
    gender,
    locale,
    membershipStatus,
    isLoggedIn,
    isPreview,
    loyalty,
    vipPlusPerksAvailable,
    topSize,
    bottomSize,
    braSize,
    options = {},
  } = pageOptions;

  const queryGender = gender === Gender.MALE ? 'male' : 'female';
  /* original/final implementation, 
  but for PROD testing we use the reward_tier_level_id and map them to the corresponding labels for our test countries
  ////////// enable the following line again:
  const loyaltyTierLabel = loyalty?.tier?.label;
  //////////
  remove that after new Loyalty program launch */
  const loyaltyRewardTierId = loyalty?.tier?.membershipRewardTierId;
  let loyaltyTierLabel =
    (locale === 'en-CA' ||
      locale === 'en-GB' ||
      locale === 'fr-FR' ||
      locale === 'es-ES' ||
      locale === 'nl-NL' ||
      locale === 'sv-SE' ||
      locale === 'da-DK') &&
    loyaltyRewardTierId in MembershipRewardTierIdMapping
      ? MembershipRewardTierIdMapping[loyaltyRewardTierId]
      : loyalty?.tier?.label;
  loyaltyTierLabel = loyaltyTierLabel?.length ? loyaltyTierLabel : null;
  /// remove the above

  const hasFringeSize =
    isFringeSize(bottomSize) || isFringeSize(topSize) || isFringeSize(braSize);

  return {
    userAttributes: {
      brand,
      experienceId,
      gender: queryGender,
      locale,
      membershipStatus,
      loggedIn: isLoggedIn,
      platform: 'web',
      loyaltyTierLabel,
      vipPlusPerksAvailable: vipPlusPerksAvailable || false,
      bottomProfileSize: bottomSize,
      topProfileSize: topSize,
      braProfileSize: braSize,
      hasFringeSize,
    },
    options: {
      includeUnpublished: isPreview,
      ...options,
      locale,
    },
  };
};
