import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import LoadingIcon from '~/icons/components/LoadingIcon';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import VisuallyHidden from '../VisuallyHidden';

const Spinner = styled(LoadingIcon)`
  animation: ${({ theme }) => theme.animations.keyframes.spin} 1.5s linear
    infinite;
`;

const Wrapper = styled.span`
  margin-bottom: 8px;

  ${({ $center }) =>
    $center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export default function LoadingSpinner({ center, className }) {
  return (
    <>
      <VisuallyHidden>
        <FormattedMessage id="global_cta.loading" defaultMessage="loading" />
      </VisuallyHidden>
      <Wrapper $center={center}>
        <Spinner size="58" className={className} />
      </Wrapper>
    </>
  );
}

LoadingSpinner.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
};
