// Search Input
export const DEBOUNCE_DELAY = 500;
export const MIN_CHARS_TO_SEARCH = 3;

// Search History
export const MAX_HISTORY_LENGTH = 3;
export const HISTORY_STORAGE_KEY = 'cioSearchHistory';

// API Query Count
export const TRENDING_SEARCHES_COUNT = 3;
export const TOP_SUGGESTIONS_COUNT = 6;
export const TOP_PRODUCTS_COUNT = 5;
export const MOBILE_PRODUCT_COUNT = 6;
export const DESKTOP_PRODUCT_COUNT = 5;

// API Stale Time
export const RECOMMENDATION_API_STALE_TIME = 1000 * 60 * 30; // 30 minutes
export const AUTOCOMPLETE_API_STALE_TIME = 1000 * 60 * 60; // 1 hour

// tracking
export const QUERY_TYPE_SEARCH_SUBMITTED = 'search-submitted';
export const QUERY_TYPE_TOP_SUGGESTION = 'top-suggestions';
export const QUERY_TYPE_TRENDING_SEARCHES = 'trending-searches';
export const QUERY_TYPE_RECENT_SEARCHES = 'recent-searches';
export const PSRC_TRENDING_PRODUCTS = 'cio-search-trending-products';
export const PSRC_PREFIX_FEATURED_PRODUCTS = 'cio-search-featured:';

// CIO Search
export const LOCALIZED_LABELS = {
  'filter-size': {
    'en-US': 'Size',
    'en-CA': 'Size',
    'en-GB': 'Size',
    'de-DE': 'Größe',
    'fr-FR': 'Taille',
    'es-ES': 'Talla',
    'nl-NL': 'Size',
    'da-DK': 'Size',
    'sv-SE': 'Size',
  },
  'filtered-by': {
    'en-US': 'Filtered by:',
    'en-CA': 'Filtered by:',
    'en-GB': 'Filtered by:',
    'de-DE': 'Gefiltert nach:',
    'fr-FR': 'Filtrer par :',
    'es-ES': 'Filtrado por:',
    'nl-NL': 'Filtered by:',
    'da-DK': 'Filtered by:',
    'sv-SE': 'Filtered by:',
  },
  'clear-all': {
    'en-US': 'Clear all',
    'en-CA': 'Clear all',
    'en-GB': 'Clear all',
    'de-DE': 'Alles löschen',
    'fr-FR': 'Effacer tout',
    'es-ES': 'Borrar todo',
    'nl-NL': 'Clear all',
    'da-DK': 'Clear all',
    'sv-SE': 'Clear all',
  },
};
