import { Gender } from '~/techstyle-shared/react-accounts';
import { createSelector } from '~/techstyle-shared/redux-core';

import {
  aggregationKeyMap,
  clothingSizes,
  mensBottomSizes,
  mensPdpBottomSizes,
  mensPdpTopSizes,
  mensTopSizes,
  pdpClothingSizes,
  PROFILE_KEYS,
} from '../constants/productSizes';

import { getProfileFilterValue } from './getProfileFilterValue';

/**
 * Selector to retrieve the user's profile sizes as an object mapping
 * aggregation field to filter value.
 */
export const getProfileSizeFilters = createSelector(
  [
    (customer) => customer.profile,
    (customer, gender) => ({ isMale: gender === Gender.MALE }),
    (customer, gender, isPdp) => isPdp,
  ],
  (profile, { isMale }, isPdp) => {
    if (!profile) {
      return null;
    }

    return Object.entries(aggregationKeyMap).reduce(
      (aggregationValues, [aggregationField, profileKey]) => {
        const profileValue = profile[profileKey];
        // Assume a value of `0` is valid.
        if (profileValue != null && profileValue !== '') {
          const filterValue = isMale
            ? getMensProfileFilterValue(profileKey, profileValue, isPdp)
            : getProfileFilterValue({
                profileKey,
                profileValue,
                isPdp,
                subField: aggregationField,
              });

          if (filterValue != null) {
            aggregationValues[aggregationField] = filterValue;
          }
        }
        return aggregationValues;
      },
      {}
    );
  }
);

function getMensProfileFilterValue(profileKey, profileValue, isPdp = false) {
  switch (profileKey) {
    case PROFILE_KEYS.BOTTOM_SIZE:
      return isPdp
        ? mensPdpBottomSizes[profileValue]
        : mensBottomSizes[profileValue];
    case PROFILE_KEYS.TOP_SIZE:
      return isPdp ? mensPdpTopSizes[profileValue] : mensTopSizes[profileValue];
    case PROFILE_KEYS.BRA_SIZE:
    case PROFILE_KEYS.BRALETTE_SIZE:
    case PROFILE_KEYS.DRESS_SIZE:
    case PROFILE_KEYS.ONE_PIECE_SIZE:
      return isPdp
        ? pdpClothingSizes[profileValue]
        : clothingSizes[profileValue];
    case PROFILE_KEYS.SHOE_SIZE:
      // Shoe sizes are already valid as-is.
      return profileValue.toString();
  }
}
