import React from 'react';

import PropTypes from 'prop-types';

export default function LoadingIcon({
  color = 'currentColor',
  size = '1em',
  strokeWidth = '1',
  ...rest
}) {
  // In case we ever want to change the geometry of the loading icon, here are the
  //   coordinates for the svg path
  // const radius = 42;
  // const centerX = 44;
  // const centerY = 44;
  // const startAngle = (0.5 / 2) * Math.PI / 360; // convert degrees to radians
  // const endAngle = (359.5 / 2) * Math.PI / 360; // convert degrees to radians
  // const startX = centerX + radius * Math.cos(startAngle);
  // const startY = centerY - radius * Math.sin(startAngle);
  // const endX = centerX + radius * Math.cos(endAngle);
  // const endY = centerY - radius * Math.sin(endAngle);

  return (
    <svg
      width={size}
      height={size}
      {...rest}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 85.99990004744878 43.908370286957854 A 42 42 0 1 1 44.09162971304214 2.000099952551224"
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
      />
    </svg>
  );
}

LoadingIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
