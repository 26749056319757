import { useSelector } from 'react-redux';

import { getProfileSizeFilters } from '~/shared/utils/getProfileSizeFilters';
import useGender from '~/shared/utils/useGender';
import { useCustomer } from '~/techstyle-shared/react-accounts';
import { useSessionDetails } from '~/techstyle-shared/redux-core';

export default function useProfileSizes({ isPdp = true } = {}) {
  const { networkStatus: customerNetworkStatus } = useCustomer();
  const { networkStatus: sessionDetailsNetworkStatus } = useSessionDetails();
  const { gender } = useGender();

  const profileSizesAvailable =
    customerNetworkStatus.isUpToDate &&
    sessionDetailsNetworkStatus.isUpToDate &&
    !customerNetworkStatus.isLoading &&
    !sessionDetailsNetworkStatus.isLoading;

  const profileSizes = useSelector((state) =>
    profileSizesAvailable
      ? getProfileSizeFilters(state.customer, gender, isPdp)
      : false
  );
  return {
    profileSizes,
  };
}
