import { getHomepageMemberStatus } from '../../acquisition/utils/getHomePageMemberStatus';

// Targeting prescribed in builder.io
export const builderMemberStatus = {
  loggedOut: 'loggedOut',
  vip: 'vip',
  downgraded: 'downgraded',
  postreg: 'postreg',
  lead: 'lead',
};

export const getBuilderMembershipStatus = (membership, isLoggedIn = true) => {
  if (!isLoggedIn) {
    return builderMemberStatus.loggedOut;
  }

  const { isVip, isDowngraded, isPostReg, isLead } =
    getHomepageMemberStatus(membership);
  if (isVip) {
    return builderMemberStatus.vip;
  } else if (isDowngraded) {
    return builderMemberStatus.downgraded;
  } else if (isPostReg) {
    return builderMemberStatus.postreg;
  } else if (isLead) {
    return builderMemberStatus.lead;
  }
  return null;
};
