import config from 'config';

export const builderAutomaticSizeConfigValues = {
  USE_FEATURE_FLAG: 'use-feature-flag',
  DISABLED: 'disable-automatic-size-filtering',
  PROFILE_SIZE_ONLY: 'profile-size-only',
  PROFILE_SIZE_WITH_UP_DOWN: 'profile-size-with-up-down',
};

export const plusSizes = new Set([
  '1X',
  '2X',
  '3X',
  '4X',
  '5X',
  '6X',
  '1X/2X',
  '3X/4X',
  'XXL/1X',
  'XXL-1X',
]);

// TODO? This will need to be altered if there are differences
// TODO? between the way categories are sized
export const clothingSizes = config.get('public.brand.clothingSizes');

export const pdpClothingSizes = {
  0: 'XXS',
  2: 'XXS',
  4: 'XS',
  6: 'S',
  8: 'M',
  10: 'L',
  11: 'L',
  12: 'L',
  14: 'XL',
  15: 'XL',
  16: 'XL',
  18: 'XXL',
  19: '1X',
  20: '2X',
  22: '3X',
  24: '4X',
  26: '5X',
  28: '6X',
  // Mens:
  27: 'XS',
  29: 'S',
  31: 'M',
  33: 'L',
  35: 'XS',
  36: 'XL',
  37: 'S',
  39: 'XXL',
  41: 'L',
  44: 'XL',
  47: 'XXL',
};

export const mensBottomSizes = {
  27: 'X-Small',
  29: 'Small',
  31: 'Medium',
  33: 'Large',
  36: 'X-Large',
  39: 'XX-Large',
  45: '2X',
  49: '3X',
  53: '4X',
};

export const mensPdpBottomSizes = {
  27: 'XS',
  29: 'S',
  31: 'M',
  33: 'L',
  36: 'XL',
  39: 'XXL',
  45: '2X',
  49: '3X',
  53: '4X',
};

export const mensTopSizes = {
  35: 'X-Small',
  37: 'Small',
  39: 'Medium',
  41: 'Large',
  44: 'X-Large',
  47: 'XX-Large',
  49: '2X',
  53: '3X',
  57: '4X',
};

export const mensPdpTopSizes = {
  35: 'XS',
  37: 'S',
  39: 'M',
  41: 'L',
  44: 'XL',
  47: 'XXL',
  49: '2X',
  53: '3X',
  57: '4X',
};
export const sizeAbbrToSizeStringMap = {
  XXS: 'XX-Small',
  XS: 'X-Small',
  S: 'Small',
  M: 'Medium',
  L: 'Large',
  XL: 'X-Large',
  XXL: 'XX-Large',
  '1X': '1X',
  '2X': '2X',
  '3X': '3X',
  '4X': '4X',
  '5X': '5X',
  '6X': '6X',
};

export const fringeSizes = [
  sizeAbbrToSizeStringMap.XXS,
  sizeAbbrToSizeStringMap.XS,
  sizeAbbrToSizeStringMap.XL,
  sizeAbbrToSizeStringMap.XXL,
  sizeAbbrToSizeStringMap['1X'],
  sizeAbbrToSizeStringMap['2X'],
  sizeAbbrToSizeStringMap['3X'],
  sizeAbbrToSizeStringMap['4X'],
  sizeAbbrToSizeStringMap['5X'],
  sizeAbbrToSizeStringMap['6X'],
];

const swapKeyValues = (obj) =>
  Object.keys(obj).reduce((ret, key) => {
    if (!ret[obj[key]]) {
      ret[obj[key]] = key;
    }
    return ret;
  }, {});

export const cookieSizes = swapKeyValues(clothingSizes);

export const PROFILE_KEYS = {
  BOTTOM_SIZE: 'bottom-size',
  BRA_SIZE: 'bra-size',
  BRALETTE_SIZE: 'bralette-size',
  DRESS_SIZE: 'dress-size',
  ONE_PIECE_SIZE: 'one-piece-size',
  SHOE_SIZE: 'shoe-size',
  TOP_SIZE: 'top-size',
  LINGERIE_SIZE: 'lingerie-sleep-size',
};

export const cookieSizeMap = {
  'FL-QUICK-SIZE-BRA': ['bra-size', 'size_bra'],
  'FL-QUICK-SIZE-BOTTOM': ['bottom-size', 'size_bottom'],
  'FL-QUICK-SIZE-DRESS': ['dress-size', 'size_dress'],
  'FL-QUICK-SIZE-SHOE': ['shoe-size', 'size_shoe'],
  'FL-QUICK-SIZE-TOP': ['top-size', 'size_top'],
};

// * This links the keys expected by the (aggregations) API against the brand
// * size keys returned by `useCustomer()`
export const aggregationKeyMap = {
  size_bottom: 'bottom-size',
  size_bra: 'bra-size',
  size_bralette: 'bralette-size',
  size_dress: 'dress-size',
  size_shoes: 'shoe-size',
  size_top: 'top-size',
  size_one_piece: 'one-piece-size',
  size_cup: 'bra-size',
  size_band: 'bra-size',
  size_lingerie: 'lingerie-sleep-size',
};
