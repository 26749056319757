export const LoyaltyTiers = {
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  BLACK: 'Black',
};

export const MembershipRewardTierIdMapping = {
  /* UK */
  37: LoyaltyTiers.GOLD,
  38: LoyaltyTiers.PLATINUM,
  39: LoyaltyTiers.BLACK,
  /* CA */
  31: LoyaltyTiers.GOLD,
  32: LoyaltyTiers.PLATINUM,
  33: LoyaltyTiers.BLACK,
  /* FR */
  40: LoyaltyTiers.GOLD,
  41: LoyaltyTiers.PLATINUM,
  42: LoyaltyTiers.BLACK,
  /* ES */
  43: LoyaltyTiers.GOLD,
  44: LoyaltyTiers.PLATINUM,
  45: LoyaltyTiers.BLACK,
  /* NL */
  46: LoyaltyTiers.GOLD,
  47: LoyaltyTiers.PLATINUM,
  48: LoyaltyTiers.BLACK,
  /* SE */
  49: LoyaltyTiers.GOLD,
  50: LoyaltyTiers.PLATINUM,
  51: LoyaltyTiers.BLACK,
  /* DK */
  52: LoyaltyTiers.GOLD,
  53: LoyaltyTiers.PLATINUM,
  54: LoyaltyTiers.BLACK,
  /* CA */
  55: LoyaltyTiers.GOLD,
  56: LoyaltyTiers.PLATINUM,
  57: LoyaltyTiers.BLACK,
};
