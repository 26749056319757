import React, { useEffect, useMemo, useState } from 'react';

import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import config from 'config';
import PropTypes from 'prop-types';

import useBuilderTargeting from '~/builder/utils/useBuilderTargeting';
import { useMembership } from '~/techstyle-shared/react-accounts';
import { useDomain } from '~/techstyle-shared/redux-core';

const CioContext = React.createContext();

const useCioClient = () => {
  return React.useContext(CioContext);
};

const transformUserAttributesToCioSegments = (userAttributes) => {
  if (!userAttributes) {
    return [];
  }

  return Object.entries(userAttributes)
    .filter(([key, value]) => Boolean(value))
    .map(([key, value]) => `${key}|${value}`);
};

const CioProvider = ({ children }) => {
  const { tld } = useDomain();
  const { customerId } = useMembership();

  const apiKeys = config.get('public.constructorio.apiKeys');
  const apiKey = apiKeys?.[tld];

  const [cioClient, setCioClient] = useState(null);
  const { userAttributes } = useBuilderTargeting({ isPreview: false });
  const segments = useMemo(
    () => transformUserAttributesToCioSegments(userAttributes),
    [userAttributes]
  );

  useEffect(() => {
    setCioClient(
      new ConstructorIOClient({
        segments,
        apiKey,
        userId: customerId,
      })
    );
  }, [apiKey, customerId, segments]);

  return (
    <CioContext.Provider value={cioClient}>{children}</CioContext.Provider>
  );
};

CioProvider.propTypes = {
  children: PropTypes.node,
};

export { CioProvider, useCioClient };
