import { parseBraSize } from '~/techstyle-shared/react-components/src/utils/sizes';

import {
  clothingSizes,
  pdpClothingSizes,
  PROFILE_KEYS,
} from '../constants/productSizes';

export function getProfileFilterValue({
  profileKey,
  profileValue,
  isPdp = false,
  subField,
}) {
  switch (profileKey) {
    case PROFILE_KEYS.BRA_SIZE:
      if (subField && subField === 'size_cup') {
        return parseBraSize(profileValue)[1];
      } else if (subField && subField === 'size_band') {
        return parseBraSize(profileValue)[0];
      } else {
        return isPdp
          ? pdpClothingSizes[profileValue]
          : clothingSizes[profileValue];
      }

    case PROFILE_KEYS.BOTTOM_SIZE:
    case PROFILE_KEYS.TOP_SIZE:
    case PROFILE_KEYS.LINGERIE_SIZE:
    case PROFILE_KEYS.BRALETTE_SIZE:
    case PROFILE_KEYS.DRESS_SIZE:
    case PROFILE_KEYS.ONE_PIECE_SIZE:
      return isPdp
        ? pdpClothingSizes[profileValue]
        : clothingSizes[profileValue];
    case PROFILE_KEYS.SHOE_SIZE:
      // Shoe sizes are already valid as-is.
      return profileValue.toString();
  }
}
